<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "CarouselItem",
};
</script>
<style scoped></style>
