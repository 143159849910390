<template>
  <card>
    <!-- Header -->
    <template v-slot:header>
      <div class="header">
        <h5 class="modal-title">Add a Payment Schedule</h5>
        <i class="fa fa-times text-grey" @click="closePopOver"></i>
      </div>
    </template>
    <!-- Body -->
    <div>
      <div class="radios__wrap">
        <base-radio class="mb-3 mr-3" name="percent" v-model="paymentType">
          Percentage
        </base-radio>

        <base-radio class="mb-3" name="flat" v-model="paymentType">
          Flat Amount
        </base-radio>
      </div>
      <div class="form__wrapper" v-for="(pay, index) in payments" :key="index">
        <div class="form__child-1">
          Payment ({{ index + 1 }} of {{ payments.length }})
          <div class="input__payment">
            <base-input
              class="mb-0 mr-1"
              v-model="pay.value"
              :placeholder="placeholder"
              @keypress="isNumber($event, false, 5, false)"
            ></base-input>
            {{ suffix }}
          </div>
        </div>
        <div class="form__child-2">
          Due Date
          <base-single-date
            :value="pay.dueDate"
            @change="setDueDate($event, index)"
          ></base-single-date>
        </div>
        <div class="form__child-3">
          <i class="fa fa-trash text-gray" @click="removePayment(index)"></i>
        </div>
      </div>
      <div class="add-another-payment py-2" @click="addNewPayment">
        + Add Another Payment
      </div>
      <div class="conclusion__amounts">
        <div>Total</div>
        <div>{{ displayScheduledAmount }}</div>
      </div>
      <div class="amount__left">{{ displayBalanceAmount }}</div>
    </div>
    <!-- Footer -->
    <template v-slot:footer>
      <base-button type="secondary" @click="closePopOver">Close</base-button>
      <base-button type="primary" @click="emitSchedule"
        >Add Schedule</base-button
      >
    </template>
  </card>
</template>
<script>
import moment from "moment/moment";
export default {
  name: "PopPaymentSchedule",
  props: {
    currency: {
      type: String,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      paymentType: "percent",
      payments: [
        {
          value: "",
          dueDate: moment().format(),
        },
      ],
    };
  },
  computed: {
    placeholder() {
      if (this.paymentType === "percent") return "0%";
      return `${this.currency}0`;
    },
    suffix() {
      if (this.paymentType === "percent") return "%";
      return "";
    },
    scheduledAmount() {
      return this.payments.reduce((tot, el) => tot + +el.value, 0);
    },
    displayScheduledAmount() {
      if (this.paymentType === "percent")
        return `${this.scheduledAmount.toFixed(2)}%`;
      return `${this.currency}${this.scheduledAmount.toFixed(2)}`;
    },
    balanceAmount() {
      if (this.paymentType === "flat")
        return this.totalAmount - this.scheduledAmount;
      return 100 - this.scheduledAmount;
    },
    displayBalanceAmount() {
      let bal = this.balanceAmount;
      let suffix = "left";
      if (bal < 0) {
        bal = Math.abs(bal);
        suffix = "over";
      }
      if (this.paymentType === "percent") {
        return `${bal}% ${suffix}`;
      }
      return `${this.currency}${bal} ${suffix}`;
    },
  },
  methods: {
    closePopOver() {
      this.$emit("close");
    },
    setDueDate(event, index) {
      this.payments[index].dueDate = moment(event).format();
    },
    addNewPayment() {
      this.payments.push({
        value: "",
        dueDate: moment().format(),
      });
    },
    removePayment(index) {
      this.payments = this.payments.filter((el, ind) => {
        return ind !== index;
      });
    },
    emitSchedule() {
      this.$emit("add-payment-schedule", [...this.payments]);
      this.closePopOver();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal__payment-schedule {
  max-width: 650px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.fa-times {
  cursor: pointer;
}
.radios__wrap {
  display: flex;
}
.form__wrapper {
  display: grid;
  grid-template-columns: 45% 45% 10%;
  .form__child-1,
  .form__child-2 {
    justify-self: center;
    align-self: start;
  }
  .form__child-3 {
    justify-self: center;
    align-self: center;
    i {
      cursor: pointer;
    }
  }
}
.input__payment {
  display: flex;
  align-items: center;
}
.add-another-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed grey;
  border-radius: 4px;
  max-width: 90%;
  margin: 2rem auto;
  cursor: pointer;
}
.add-another-payment:hover {
  background-color: #ddffe1;
}
.conclusion__amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount__left {
  text-align: right;
  font-size: 13px;
  color: lightslategrey;
}
</style>
