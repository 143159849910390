<template>
  <card>
    <!-- Header -->
    <template v-slot:header>
      <div class="header">
        <h5 class="modal-title">Add Taxes</h5>
        <i class="fa fa-times text-grey" @click="closePopOver"></i>
      </div>
    </template>
    <!-- Body -->
    <div>
      <div class="form__wrapper">
        <div class="">Tax Rate</div>
        <div class="">Tax Name</div>
      </div>
      <div
        class="form__wrapper mt-2"
        v-for="(tax, index) in taxes"
        :key="index"
      >
        <!-- Tax Percent value -->
        <div class="form__child-1">
          <div class="input__tax">
            <base-input
              class="mb-0 mr-1"
              v-model="tax.value"
              placeholder="0.00%"
              @keypress="isNumber($event, false, 5, false)"
            ></base-input>
            %
          </div>
        </div>
        <!-- Tax Name -->
        <div class="form__child-2">
          <base-input
            class="mb-0 mr-1"
            v-model="tax.name"
            placeholder="Tax Name"
          ></base-input>
        </div>
        <div class="form__child-3">
          <i class="fa fa-trash text-gray" @click="removeTax(index)"></i>
        </div>
      </div>
      <div class="add-another-tax py-2" @click="addNewTax">
        + Add Another Tax
      </div>
    </div>
    <!-- Footer -->
    <template v-slot:footer>
      <base-button type="secondary" @click="closePopOver">Close</base-button>
      <base-button type="primary" @click="emitTaxes">Apply Taxes</base-button>
    </template>
  </card>
</template>
<script>
export default {
  name: "AddTaxes",
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taxes: [
        {
          value: "",
          name: "",
        },
      ],
    };
  },
  computed: {
    // returns true if all the tax fields are filled up
    areAllTaxNamesFilled() {
      return this.taxes.every((tax) => tax.name && tax.value);
    },
  },
  methods: {
    closePopOver() {
      this.$emit("close");
    },
    addNewTax() {
      this.taxes.push({
        value: "",
        name: "",
      });
    },
    removeTax(index) {
      this.taxes = this.taxes.filter((el, ind) => {
        return ind !== index;
      });
    },
    emitTaxes() {
      if (!this.areAllTaxNamesFilled) return;
      this.$emit("add-taxes", [...this.taxes]);
      this.closePopOver();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal__add-taxes {
  max-width: 650px !important;
}
.header {
  display: flex;
  justify-content: space-between;
}
.fa-times {
  cursor: pointer;
}
.radios__wrap {
  display: flex;
}
.form__wrapper {
  display: grid;
  grid-template-columns: 45% 45% 10%;
  .form__child-1,
  .form__child-2 {
    align-self: start;
  }
  .form__child-3 {
    justify-self: center;
    align-self: center;
    i {
      cursor: pointer;
    }
  }
}
.input__tax {
  display: flex;
  align-items: center;
}
.add-another-tax {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed grey;
  border-radius: 4px;
  max-width: 90%;
  margin: 2rem auto;
  cursor: pointer;
}
.add-another-tax:hover {
  background-color: #ddffe1;
}
.conclusion__amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount__left {
  text-align: right;
  font-size: 13px;
  color: lightslategrey;
}
</style>
