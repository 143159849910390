<template>
  <div class="carousel-component">
    <div
      :class="'carousel-page' + (current === index ? ' active' : '')"
      v-for="(item, index) in items"
      :key="index"
      :style="'transform: translate3d(' + position(index) * 100 + '%, 0, 0)'"
    >
      <vnodes :vnodes="item" />
    </div>
    <button class="btn carousel-nav-prev" @click="decreaseCurrent">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </button>
    <button class="btn carousel-nav-next" @click="increaseCurrent">
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  components: {
    vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    items() {
      return this.$slots.default.filter(function(item) {
        return (
          item.componentOptions !== undefined &&
          item.componentOptions.tag === "carousel-item"
        );
      });
    },
  },
  methods: {
    decreaseCurrent: function() {
      this.current += this.items.length - 1;
      this.current %= this.items.length;
    },
    increaseCurrent() {
      this.current += 1;
      this.current %= this.items.length;
    },
    position(index) {
      if (index === this.current) return 0;
      if (index === (this.current + 1) % this.items.length) return 1;
      return -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-component {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 13px;
}
.carousel-page {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.5s;
}
.carousel-page h2 {
  margin: 0;
}
.carousel-page img {
  width: 300px;
}
.carousel-page.active {
  visibility: visible;
  position: static;
}
.carousel-nav-prev,
.carousel-nav-next {
  position: absolute;
  top: 129px;
  width: 40px;
  height: 40px;
  background: #e6e6e6b8;
  border-radius: 50px;
  color: #000;
  margin-left: 25px;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
  text-indent: -2px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  i {
    top: -4px;
    position: relative;
  }
}
.carousel-nav-prev:hover,
.carousel-nav-next:hover {
  background: #e6e6e6e3;
  color: #000;
  transform: scale(1.2);
}
.btn:focus {
  box-shadow: none !important;
}
.carousel-nav-prev {
  left: 6px;
}
.carousel-nav-next {
  right: 0;
  margin-left: auto;
  margin-right: 29px;
}
@media only screen and (max-width: 460px) {
  .carousel-nav-prev,
  .carousel-nav-next {
    width: 30px !important;
    height: 30px !important;
    line-height: 14px !important;
    text-indent: -2px !important;
  }
}
</style>
