<template>
  <card>
    <!-- Header -->
    <template v-slot:header>
      <div class="header">
        <h5 class="modal-title">Add a Discount</h5>
        <i class="fa fa-times text-grey" @click="closePopOver"></i>
      </div>
    </template>
    <!-- Body -->
    <div>
      <div class="radios__wrap">
        <base-radio class="mb-3 mr-3" name="percent" v-model="discountType">
          Percentage
        </base-radio>

        <base-radio class="mb-3" name="flat" v-model="discountType">
          Flat Amount
        </base-radio>
      </div>
      <div class="textbox__wrap">
        <base-input
          class="mb-0 mr-1"
          :required="true"
          :placeholder="placeholder"
          v-model="discountValue"
          @keypress="isNumber($event, false, 5, false)"
        ></base-input>
        {{ suffix }}
      </div>
    </div>
    <!-- Footer -->
    <template v-slot:footer>
      <base-button type="secondary" @click="closePopOver">Close</base-button>
      <base-button type="primary" @click="saveDiscount"
        >Save changes</base-button
      >
    </template>
  </card>
</template>

<script>
export default {
  name: "PopDiscount",
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      discountType: "percent",
      discountValue: "",
    };
  },
  computed: {
    placeholder() {
      if (this.discountType === "flat") return `${this.currency}0`;
      else return `0%`;
    },
    suffix() {
      if (this.discountType === "flat") return `of subtotal`;
      else return `% of subtotal`;
    },
  },
  methods: {
    closePopOver() {
      this.$emit("close");
    },
    saveDiscount() {
      this.$emit("set-discount", {
        type: this.discountType,
        value: this.discountValue,
      });
      this.closePopOver();
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.fa-times {
  cursor: pointer;
}
.textbox__wrap {
  display: flex;
  align-items: center;
}
</style>
