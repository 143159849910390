<template>
  <div class="product__card p-3">
    <div class="head">
      <div class="title">
        <h2>{{ product.title }}</h2>
        <h6>{{ product.shortDesc }}</h6>
      </div>
      <div class="title"><i class="fas fa-ellipsis-v"></i></div>
    </div>
    <div class="body">
      <span>
        {{ product.longDesc }}
      </span>
      <carousel>
        <carousel-item v-for="(photo, index) in product.carousel" :key="index">
          <carousel-item-photo :photo="photo"></carousel-item-photo>
        </carousel-item>
      </carousel>
      <div class="prices mt-3">
        <div class="stock">
          <span>Stock</span>
          <span>{{ product.stock }}</span>
        </div>
        <div class="sale-price">
          <span>Sale Price</span>
          <span>{{ product.salePrice }}</span>
        </div>
      </div>
    </div>
    <div class="foot"></div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel/Carousel";
import CarouselItem from "@/components/Carousel/CarouselItem";
import CarouselItemPhoto from "@/views/ItemsAndServices/view/CarouselItemPhoto.vue";
export default {
  name: "ViewItemCard",
  components: { Carousel, CarouselItemPhoto, CarouselItem },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.product__card {
  box-shadow: 2px 2px 7px 0px #888888;
  border-radius: 6px;
  margin: 0 auto;
  max-width: 700px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      cursor: pointer;
    }
  }
  .prices {
    display: flex;
    justify-content: space-around;
    .stock,
    .sale-price {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    span {
      font-weight: 800;
    }
  }
  .foot {
    display: flex;
    justify-content: space-around;
  }
}
</style>
