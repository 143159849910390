<template>
  <div class="carousel__image-wrap">
    <img class="carousel__image-cover" :src="photo" />
  </div>
</template>
<script>
export default {
  name: "ItemCard",
  props: {
    photo: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.carousel__image-wrap {
  display: flex;
  flex-direction: column;
}
.carousel__image-cover {
  object-fit: cover;
  height: 250px;
}
</style>
