<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Expense</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button type="success">Save</base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- Category -->
              <div class="row">
                <div class="col-md-5">
                  <base-multiselect-dropdown
                    parentLabel="Category (What is the expense for?)"
                    :required="true"
                    v-model="expense.category"
                    :options="expense.options"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    :multiple="true"
                    trackBy="id"
                    :taggable="true"
                    label="expense"
                    :maxHeight="600"
                    :optionHeight="300"
                    placeholder="Add Category"
                    @tag="addExpense"
                  ></base-multiselect-dropdown>
                </div>
                <div class="col-md-5">
                  <base-input
                    label="Name"
                    :required="true"
                    placeholder="Name"
                    v-model="merchantName"
                  ></base-input>
                </div>
              </div>
              <!-- Splits & Taxes -->
              <div class="row">
                <!-- Splits -->
                <div class="col-md-5">
                  <label class="form-control-label">Splits:</label>
                  <div
                    class="link__add-splits"
                    v-show="!splits.list.length"
                    @click="splits.isPopOpen = true"
                  >
                    +Add Splits
                  </div>
                  <modal
                    :show="splits.isPopOpen"
                    modal-classes="modal__add-splits"
                    ><pop-add-splits
                      :currency="currency"
                      @close="splits.isPopOpen = false"
                      @add-splits="setSplits"
                    ></pop-add-splits
                  ></modal>
                  <ul
                    v-show="splits.list.length"
                    @click="splits.isPopOpen = true"
                  >
                    <li
                      v-for="(split, index) in splits.list"
                      :key="index"
                      class="link__add-splits"
                    >
                      ${{ split.value }} {{ split.name }}
                    </li>
                  </ul>
                </div>
                <!-- Taxes -->
                <div class="col-md-5">
                  <label class="form-control-label">Taxes:</label>
                  <div
                    class="link_add-taxes"
                    v-show="!taxes.list.length"
                    @click="taxes.isPopOpen = true"
                  >
                    +Add Taxes
                  </div>
                  <modal
                    :show="taxes.isPopOpen"
                    modal-classes="modal__add-taxes"
                    ><pop-add-taxes
                      :currency="currency"
                      @close="taxes.isPopOpen = false"
                      @add-taxes="setTaxes"
                    ></pop-add-taxes
                  ></modal>
                  <ul
                    v-show="taxes.list.length"
                    @click="taxes.isPopOpen = true"
                  >
                    <li
                      v-for="(tax, index) in taxes.list"
                      :key="index"
                      class="link_add-taxes"
                    >
                      +{{ tax.value }}% {{ tax.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Date & Payment Method-->
              <div class="row mt-3">
                <!-- Payment Method -->
                <div class="col-md-5">
                  <base-multiselect-dropdown
                    parentLabel="Payment Method"
                    :required="true"
                    v-model="paymentMethod.value"
                    :options="paymentMethod.options"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    :multiple="false"
                    :maxHeight="600"
                    :optionHeight="300"
                    placeholder=""
                  ></base-multiselect-dropdown>
                </div>
                <!-- Date -->
                <div class="col-md-5">
                  <label class="form-control-label">Add Date:</label>
                  <base-single-date
                    v-model="date"
                    @change="setDate"
                  ></base-single-date>
                </div>
              </div>
              <!-- Description  -->
              <div class="row mb-3">
                <div class="col-md-5">
                  <label class="form-control-label">Description</label>
                  <textarea
                    class="form-control"
                    v-model="description"
                    rows="3"
                    placeholder="Description"
                  ></textarea>
                </div>
                <!-- Receipt -->
                <div class="col-md-5">
                  <label class="form-control-label">Receipt:</label>
                  <base-image-input @image-selected="setReceiptImage">
                    Select image
                  </base-image-input>
                </div>
              </div>
              <hr />
              <!-- Total -->
              <div class="row">
                <div class="col-md-5">
                  <h2>Total:</h2>
                  <base-input
                    :required="true"
                    :placeholder="`${currency}0.00`"
                    v-model="total"
                  ></base-input>
                </div>
              </div>
              <!-- Assign to Client -->
              <div class="row">
                <div class="col-md-5">
                  <label class="form-control-label"
                    >Assign to Client(optional):</label
                  >
                  <base-multiselect-dropdown
                    parentLabel=""
                    :required="true"
                    v-model="clients.list"
                    :options="clients.options"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    :multiple="true"
                    trackBy="id"
                    :taggable="true"
                    label="name"
                    :maxHeight="600"
                    :optionHeight="300"
                    placeholder="Select Client"
                    @tag="addNewClient"
                  ></base-multiselect-dropdown>
                </div>
                <!-- Make Recurring Schedule -->
                <div class="col-md-5">
                  <label class="form-control-label"
                    >Make Recurring(optional):</label
                  >
                  <base-switch
                    label="Make Recurring ?"
                    :value="recurring.yes"
                    @click="recurring.yes = !recurring.yes"
                  ></base-switch>
                  <hr />
                </div>
              </div>
              <section v-if="recurring.yes">
                <!-- New Expense Date -->
                <div class="row">
                  <div class="col-md-5 offset-md-5">
                    <label class="form-control-label">New Expense Date:</label>
                    <base-single-date
                      v-model="recurring.newExpenseDate"
                      @change="setRecurringDate"
                    ></base-single-date>
                    <hr />
                  </div>
                </div>
                <!-- New Expense Date -->
                <div class="row">
                  <div class="col-md-5 offset-md-5">
                    <label class="form-control-label">How Often?:</label>
                    <base-multiselect-dropdown
                      :required="true"
                      v-model="recurring.frequency.value"
                      :options="recurring.frequency.options"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="false"
                      :maxHeight="600"
                      :optionHeight="300"
                      open-direction="top"
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- Every -->
                <div class="row" v-if="recurring.frequency.value === 'Custom'">
                  <div class="col-md-2 offset-md-5">
                    <label class="form-control-label">Every:</label>
                    <base-multiselect-dropdown
                      :required="true"
                      v-model="recurring.every.value"
                      :options="recurring.every.options"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="false"
                      :maxHeight="600"
                      :optionHeight="300"
                      open-direction="top"
                    ></base-multiselect-dropdown>
                  </div>
                  <!-- Frequency -->
                  <div class="col-md-3">
                    <label class="form-control-label">Frequency:</label>
                    <base-multiselect-dropdown
                      :required="true"
                      v-model="recurring.every.freq.value"
                      :options="recurring.every.freq.options"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      :multiple="false"
                      :maxHeight="600"
                      :optionHeight="300"
                      open-direction="top"
                    ></base-multiselect-dropdown>
                  </div>
                </div>
                <!-- How Many -->
                <div class="row">
                  <!-- How Many -->
                  <div class="col-md-5 offset-md-5">
                    <hr />
                    <label class="form-control-label">How Many?:</label>
                    <base-radio
                      class="mb-3 mr-3"
                      name="infinite"
                      v-model="recurring.infinite"
                    >
                      Infinite
                    </base-radio>
                    <base-radio
                      class="mb-3 mr-3"
                      name="rmaining"
                      v-model="recurring.infinite"
                    >
                      <div class="recurring__remaining">
                        <base-input
                          :required="false"
                          v-model="recurring.remaining"
                          class="mr-2"
                        ></base-input>
                        remaining
                      </div>
                    </base-radio>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5 offset-md-5">
                    <hr />
                    <label class="form-control-label">
                      {{ finalStatement }}
                    </label>
                    <hr />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopAddSplits from "./PopAddSplits.vue";
import PopAddTaxes from "@/views/PopOvers/ItemsAndServices/PopAddTaxes.vue";
import moment from "moment/moment";
export default {
  components: { PopAddSplits, PopAddTaxes },
  name: "CreateExpense",
  data() {
    return {
      currency: `$`,
      merchantName: "",
      date: "",
      expense: {
        options: [
          { id: 1, expense: "Advertising" },
          { id: 2, expense: "IT Services" },
        ],
        category: [],
      },
      clients: {
        options: [
          { id: 1, name: "Client 1" },
          { id: 2, name: "client 2" },
        ],
        list: [],
      },
      splits: {
        isPopOpen: false,
        list: [],
      },
      paymentMethod: {
        value: "",
        options: ["Credit Card", "Bank Account"],
      },
      taxes: {
        isPopOpen: false,
        list: [],
      },
      description: "",
      total: "",
      recurring: {
        newExpenseDate: moment().format(),
        frequency: {
          value: "Weekly",
          options: ["Weekly", "Monthly", "Yearly", "Custom"],
        },
        yes: true,
        every: {
          value: 1,
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          freq: {
            value: "Week",
            options: ["Week", "Month", "Year"],
          },
        },
        infinite: "infinite",
      },
    };
  },
  computed: {
    finalStatement() {
      const expenseDate = moment(this.recurring.newExpenseDate).format(
        "MMMM DD, YYYY"
      );
      if (this.recurring.frequency.value === "Custom") {
        return `An expense will be created every ${this.recurring.every.value}
         ${this.recurring.every.freq.value}(s) starting ${expenseDate}.`;
      } else {
        return `An expense will be created ${this.recurring.frequency.value} starting ${expenseDate}.`;
      }
    },
  },
  methods: {
    // add new object to expense options
    addExpense(searchQuery) {
      const newCat = {
        id: this.expense.options.length + 1,
        expense: searchQuery,
      };
      this.expense.options.push(newCat);
      this.expense.category.push(newCat);
    },
    setSplits(splits) {
      this.splits.list = splits;
    },
    setDate(event) {
      this.date = moment(event);
    },
    setTaxes(taxes) {
      this.taxes.list = taxes;
    },
    setReceiptImage(file) {
      console.log(file);
    },
    addNewClient(searchQuery) {
      const newClient = {
        id: this.clients.options.length + 1,
        name: searchQuery,
      };
      this.clients.options.push(newClient);
      this.clients.list.push(newClient);
    },
    setRecurringDate(date) {
      this.recurring.newExpenseDate = date;
    },
  },
};
</script>
<style lang="scss" scoped>
.link__add-splits,
.link_add-taxes {
  &:hover {
    opacity: 0.6;
  }
  color: #0075dd;
  cursor: pointer;
}
hr {
  display: block;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  margin-left: auto;
  margin-right: auto;
  border-color: aquamarine;
}
.recurring__remaining {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
