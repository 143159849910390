<template>
  <card>
    <!-- Header -->
    <template v-slot:header>
      <div class="header">
        <h5 class="modal-title">Add Splits</h5>
        <i class="fa fa-times text-grey" @click="closePopOver"></i>
      </div>
    </template>
    <!-- Body -->
    <div>
      <div class="form__wrapper">
        <div class="">Amount</div>
        <div class="">Expense For</div>
      </div>
      <div
        class="form__wrapper mt-2"
        v-for="(split, index) in splits"
        :key="index"
      >
        <!-- split Percent value -->
        <div class="form__child-1">
          <div class="input__split">
            <base-input
              class="mb-0 mr-1"
              v-model="split.value"
              placeholder="$0.00"
              @keypress="isNumber($event, false, 5, false)"
            ></base-input>
          </div>
        </div>
        <!-- split Name -->
        <div class="form__child-2">
          <base-input
            class="mb-0 mr-1"
            v-model="split.name"
            placeholder="Split Name"
          ></base-input>
        </div>
        <div class="form__child-3">
          <i class="fa fa-trash text-gray" @click="removeSplit(index)"></i>
        </div>
      </div>
      <div class="add-another-split py-2" @click="addNewSplit">
        + Add Another split
      </div>
    </div>
    <!-- Footer -->
    <template v-slot:footer>
      <base-button type="secondary" @click="closePopOver">Close</base-button>
      <base-button type="primary" @click="emitSplits">Apply splits</base-button>
    </template>
  </card>
</template>
<script>
export default {
  name: "Addsplits",
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      splits: [
        {
          value: "",
          name: "",
        },
      ],
    };
  },
  computed: {
    // returns true if all the split fields are filled up
    areAllSplitNamesFilled() {
      return this.splits.every((split) => split.name && split.value);
    },
  },
  methods: {
    closePopOver() {
      this.$emit("close");
    },
    addNewSplit() {
      this.splits.push({
        value: "",
        name: "",
      });
    },
    removeSplit(index) {
      this.splits = this.splits.filter((el, ind) => {
        return ind !== index;
      });
    },
    emitSplits() {
      if (!this.areAllSplitNamesFilled) return;
      this.$emit("add-splits", [...this.splits]);
      this.closePopOver();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal__add-splits {
  max-width: 650px !important;
}
.header {
  display: flex;
  justify-content: space-between;
}
.fa-times {
  cursor: pointer;
}
.radios__wrap {
  display: flex;
}
.form__wrapper {
  display: grid;
  grid-template-columns: 45% 45% 10%;
  .form__child-1,
  .form__child-2 {
    align-self: start;
  }
  .form__child-3 {
    justify-self: center;
    align-self: center;
    i {
      cursor: pointer;
    }
  }
}
.input__split {
  display: flex;
  align-items: center;
}
.add-another-split {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed grey;
  border-radius: 4px;
  max-width: 90%;
  margin: 2rem auto;
  cursor: pointer;
}
.add-another-split:hover {
  background-color: #ddffe1;
}
.conclusion__amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount__left {
  text-align: right;
  font-size: 13px;
  color: lightslategrey;
}
</style>
