<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Item</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button type="success">Save</base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- Name -->
                <div class="col-md-5">
                  <base-input
                    label="Name"
                    v-model="name"
                    :required="true"
                    placeholder="Enter a Name"
                  ></base-input>
                </div>
                <!-- Description -->
                <div class="col-md-7">
                  <label class="form-control-label">Description</label>
                  <textarea
                    v-model="description"
                    class="form-control"
                    rows="3"
                    placeholder="Description"
                  ></textarea>
                </div>
                <!-- Category -->
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <base-multiselect-dropdown
                    parentLabel="Category"
                    :required="true"
                    v-model="category.value"
                    :options="category.options"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="true"
                    :multiple="false"
                    trackBy="id"
                    label="industry"
                    :maxHeight="600"
                    :optionHeight="300"
                    placeholder=""
                    open-direction="above"
                  ></base-multiselect-dropdown>
                </div>
                <!-- Quantity -->
                <div class="col-md-2">
                  <base-input
                    v-model="qty"
                    @keypress="isNumber($event, true, 5, false)"
                    label="Quantity"
                    :required="true"
                    placeholder="Enter Qauntity"
                  ></base-input>
                </div>
                <!-- Price -->
                <div class="col-md-2">
                  <base-input
                    v-model="price"
                    @keypress="isNumber($event, false, 5, false)"
                    label="Price"
                    :required="true"
                    :placeholder="`${currency}0.00`"
                  ></base-input>
                </div>
                <div class="col-md-3">
                  <base-image-input height="80px" @image-selected="setItemImage"
                    >Select Item image</base-image-input
                  >
                </div>
              </div>
              <div class="row mb-2">
                <!-- VAT -->
                <div class="col-md-3 vat">
                  VAT:
                  <input
                    class="mr-1"
                    type="radio"
                    v-model="vat.radio"
                    :value="1"
                  />Yes
                  <input
                    class="mr-1"
                    type="radio"
                    v-model="vat.radio"
                    :value="0"
                  />No
                  <base-input
                    v-show="vat.radio === 1"
                    v-model="vat.percent"
                    @keypress="isNumber($event, false, 5, false)"
                    :required="true"
                    placeholder="Enter VAT in %"
                  ></base-input>
                </div>
              </div>
              <div
                class="link_add-taxes"
                v-show="!taxes.list.length"
                @click="taxes.isPopOpen = true"
              >
                +Add Taxes
              </div>
              <modal :show="taxes.isPopOpen" modal-classes="modal__add-taxes"
                ><pop-add-taxes
                  :currency="currency"
                  @close="taxes.isPopOpen = false"
                  @add-taxes="setTaxes"
                ></pop-add-taxes
              ></modal>
              <ul v-show="taxes.list.length" @click="taxes.isPopOpen = true">
                <li
                  v-for="(tax, index) in taxes.list"
                  :key="index"
                  class="link_add-taxes"
                >
                  +{{ tax.value }}% {{ tax.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopAddTaxes from "@/views/PopOvers/ItemsAndServices/PopAddTaxes.vue";
export default {
  name: "CreateItem",
  components: { PopAddTaxes },
  data() {
    return {
      currency: "$",
      name: "",
      description: "",
      qty: "",
      price: "",
      itemImage: "",
      vat: {
        radio: 1,
        percent: "",
      },
      taxes: {
        isPopOpen: false,
        list: [],
      },
      category: {
        value: { industry: "Administration", id: "administration" },
        options: [
          { industry: "Administration", id: "administration" },
          { industry: "Agriculture", id: "agriculture" },
          { industry: "Home Planning", id: "home-planning" },
          { industry: "IT", id: "it" },
        ],
      },
    };
  },
  methods: {
    setItemImage(file) {
      this.itemImage = file;
    },
    setTaxes(taxes) {
      this.taxes.list = taxes;
    },
  },
};
</script>
<style lang="scss" scoped>
.link_add-taxes {
  &:hover {
    opacity: 0.6;
  }
  color: #0075dd;
  cursor: pointer;
}
</style>
