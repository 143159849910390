<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">New Invoice</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button type="success">Save</base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row-1 py-3">
                <!-- logo upload -->
                <div class="child-1">
                  <base-image-input @image-selected="setLogoFile"
                    >Select company logo</base-image-input
                  >
                </div>
                <!-- Company name & address -->
                <div class="child-2">
                  <!-- Company Name -->
                  <div class="company-name">
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.name"
                      placeholder="Company Name"
                    ></base-tiny-input>
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.street"
                      placeholder="Street"
                    ></base-tiny-input>
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.city"
                      placeholder="City"
                    ></base-tiny-input>
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.state"
                      placeholder="State/Province"
                    ></base-tiny-input>
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.zip"
                      placeholder="Zip"
                    ></base-tiny-input>
                    <base-tiny-input
                      textAlign="right"
                      v-model="company.country"
                      placeholder="Country"
                    ></base-tiny-input>
                  </div>
                </div>
              </div>
              <!-- Client details -->
              <div class="row-2">
                <!-- Billing Address -->
                <div class="child-1">
                  Billing Address:
                  <base-tiny-input
                    placeholder="Client name"
                    v-model="billing.name"
                  ></base-tiny-input>
                  <base-tiny-input
                    placeholder="Street"
                    v-model="billing.street"
                  ></base-tiny-input>
                  <base-tiny-input
                    v-model="billing.city"
                    placeholder="City"
                  ></base-tiny-input>
                  <base-tiny-input
                    v-model="billing.state"
                    placeholder="State/Province"
                  ></base-tiny-input>
                  <base-tiny-input
                    v-model="billing.zip"
                    placeholder="Zip"
                  ></base-tiny-input>
                  <base-tiny-input
                    v-model="billing.country"
                    placeholder="Country"
                  ></base-tiny-input>
                </div>
                <!-- Shipping Address -->
                <div class="child-1">
                  Shipping Address:
                  <div>
                    <base-switch
                      label="Same as Billing Address?"
                      :value="shipping.isSameAsBilling"
                      @click="
                        shipping.isSameAsBilling = !shipping.isSameAsBilling
                      "
                    ></base-switch>
                  </div>
                  <section v-show="!shipping.isSameAsBilling">
                    <base-tiny-input
                      placeholder="Client name"
                      v-model="shipping.name"
                    ></base-tiny-input>
                    <base-tiny-input
                      placeholder="Street"
                      v-model="shipping.street"
                    ></base-tiny-input>
                    <base-tiny-input
                      v-model="shipping.city"
                      placeholder="City"
                    ></base-tiny-input>
                    <base-tiny-input
                      v-model="shipping.state"
                      placeholder="State/Province"
                    ></base-tiny-input>
                    <base-tiny-input
                      v-model="shipping.zip"
                      placeholder="Zip"
                    ></base-tiny-input>
                    <base-tiny-input
                      v-model="shipping.country"
                      placeholder="Country"
                    ></base-tiny-input>
                  </section>
                </div>
                <!-- Date of Issue, Due Date -->
                <div class="child-2">
                  <div class="mb-3">
                    Date of Issue:
                    <base-single-date
                      v-model="dateOfIssue"
                      @change="setDateOfIssue"
                    ></base-single-date>
                  </div>
                  <div>
                    Due Date:
                    <base-single-date
                      v-model="dateOfDue"
                      @change="setDateOfDue"
                    ></base-single-date>
                  </div>
                </div>
                <!-- Invoice -->
                <div>
                  Invoice Number
                  <base-tiny-input
                    v-model="invoiceNum"
                    placeholder="Invoice no."
                  ></base-tiny-input>
                </div>
              </div>
              <hr class="hr__main mb-2" />
              <div class="row-3">
                <div class="child-1">
                  <div>Description</div>
                </div>
                <div class="child-2">
                  <div>Rate ({{ currency }})</div>
                </div>
                <div class="child-3">
                  <div>Qty</div>
                </div>
                <div class="child-4">
                  <div>Line Total</div>
                </div>
              </div>
              <!-- FOR LOOP Line Items  -->
              <div
                class="row-4 mb-1"
                v-for="(item, index) in lineItems"
                :key="`row-4-${index}`"
              >
                <div class="child-1">
                  <!-- Name -->
                  <base-tiny-input
                    v-model="item.name"
                    placeholder="Item Name"
                  ></base-tiny-input>
                  <!-- Desc -->
                  <base-tiny-input
                    v-model="item.desc"
                    placeholder="Item Description"
                  ></base-tiny-input>
                </div>
                <!-- Rate -->
                <div class="child-2">
                  <base-tiny-input
                    v-model="item.rate"
                    textAlign="right"
                    @input="updateLineTotal(index)"
                    @keypress="isNumber($event, false, 5, false)"
                    :placeholder="`${currency}0.00`"
                  ></base-tiny-input>
                </div>
                <!-- Qty -->
                <div class="child-3">
                  <base-tiny-input
                    v-model="item.qty"
                    textAlign="right"
                    @keypress="isNumber($event, true, 5, false)"
                    @input="updateLineTotal(index)"
                    placeholder="Qty"
                  ></base-tiny-input>
                </div>
                <!-- Total -->
                <div class="child-4">
                  {{ displayLineTotal(item.total) }}
                </div>
                <div class="remove-line-item__wrap">
                  <i
                    class="fa fa-trash text-red"
                    @click="removeLineItem(index)"
                  ></i>
                </div>
              </div>
              <!-- + Add Item Line -->
              <div class="add_item py-2" @click="addLineItem">
                + Add Item
              </div>
              <!-- Subtotal -->
              <div class="row-5">
                <div class="child-1"></div>
                <div class="child-2">
                  <!-- LABEL Subtotal -->
                  <div>Subtotal</div>
                  <!-- LABEL Discount -->
                  <div @click="discount.isPopOpen = true" class="link">
                    {{ labelDiscount }}
                  </div>
                </div>
                <div class="child-3">
                  <!-- VALUE Subtotal -->
                  <div>{{ displaySubtotal }}</div>
                  <!-- VALUE Discount -->
                  <div>{{ displayDiscount }}</div>
                  <div v-show="discount.value" class="remove-discount__wrap">
                    <i class="fa fa-trash text-red" @click="removeDiscount"></i>
                  </div>
                </div>
              </div>
              <!-- Discount Popover -->
              <modal :show="discount.isPopOpen"
                ><pop-discount
                  :currency="currency"
                  @close="discount.isPopOpen = false"
                  @set-discount="setDiscount"
                ></pop-discount
              ></modal>
              <!-- Total Amount-->
              <div class="row-6">
                <div class="child-1"></div>
                <div class="child-2">
                  <div>Total</div>
                </div>
                <div class="child-3">{{ displayTotalAmount }}</div>
              </div>
              <!-- Payment Schedule -->
              <div class="row-7">
                <div class="child-1"></div>
                <div class="child-2">
                  <!-- LABEL Payment Schedule -->
                  <div class="label">Payment Schedule</div>
                  <!-- FOR LOOP for payment schedules -->
                  <div
                    class="payment-schedule__dates"
                    v-for="(payment, index) in paymentSchedule.payments"
                    :key="`paymentDates-${index}`"
                  >
                    <!-- Payment 1 of 4 -->
                    <span class="payment__number"
                      >Payment ({{ index + 1 }} of
                      {{ paymentSchedule.payments.length }})</span
                    >
                    <!-- Due Payment Date -->
                    <span>
                      Due {{ paymentDueDateFormat(payment.dueDate) }}</span
                    >
                  </div>
                  <!-- Add payment -->
                  <div class="link" @click="paymentSchedule.isPopOpen = true">
                    Add Payment
                  </div>
                </div>
                <!-- Scheduled payments -->
                <div class="child-3">
                  <div class="remove-payment-schedule__wrap">
                    <i
                      v-show="paymentSchedule.payments.length"
                      class="fa fa-trash text-red"
                      @click="removePaymentSchedule"
                    ></i>
                  </div>
                  <!-- Label Amount Due -->
                  <div class="label">Amount Due</div>
                  <!-- FOR LOOP Amounts Scheduled -->
                  <div
                    v-for="(amount, index) in paymentSchedule.payments"
                    :key="`paymentAmount-${index}`"
                  >
                    {{ paymentAmountFormat(amount.value) }}
                  </div>
                </div>
              </div>
              <!-- MODAL PAyment Schedule -->
              <modal
                :show="paymentSchedule.isPopOpen"
                modal-classes="modal__payment-schedule"
                ><pop-payment-schedule
                  :totalAmount="totalAmount"
                  :currency="currency"
                  @close="paymentSchedule.isPopOpen = false"
                  @add-payment-schedule="setPaymentSchedule"
                ></pop-payment-schedule
              ></modal>
              <!-- Amount Due -->
              <div class="row-8">
                <div class="child-1"></div>
                <div class="child-2">
                  <div class="label">Amount Due ({{ currency }})</div>
                </div>
                <div class="child-3">{{ displayTotalAmount }}</div>
              </div>
              <hr class="hr__main mb-2" />
              <div class="row-notes">
                Notes:
                <textarea
                  class="form-control form-control-alternative"
                  rows="3"
                  placeholder="Enter notes or bank transfer details..."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopDiscount from "@/views/PopOvers/PopDiscount.vue";
import PopPaymentSchedule from "@/views/PopOvers/PopPaymentSchedule.vue";
import moment from "moment/moment";
export default {
  name: "CreateInvoice",
  components: { PopDiscount, PopPaymentSchedule },
  data() {
    return {
      currency: "$",
      invoiceNum: "00001",
      company: {
        name: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      billing: {
        name: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      shipping: {
        isSameAsBilling: true,
        name: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      dateOfIssue: moment(),
      dateOfDue: moment(),
      lineItems: [],
      discount: {
        value: 0,
        type: "percent",
        isPopOpen: false,
      },
      paymentSchedule: {
        isPopOpen: false,
        payments: [],
      },
    };
  },
  computed: {
    subtotal() {
      return this.lineItems.reduce((tot, item) => tot + +item.total, 0);
    },
    displaySubtotal() {
      return `${this.currency}${this.subtotal.toFixed(2)}`;
    },
    totalAmount() {
      return this.subtotal - this.discountFinal;
    },
    displayTotalAmount() {
      return `${this.currency}${this.totalAmount.toFixed(2)}`;
    },
    labelDiscount() {
      const amount = this.discount.value;
      if (!amount) return `Add a Discount`;
      else if (this.discount.type === "percent") return `${amount}% Discount`;
      else return `${this.currency}${amount} Discount`;
    },
    discountFinal() {
      let amountToSubtract = +this.discount.value;
      if (this.discount.type === "percent") {
        amountToSubtract = (this.discount.value * +this.subtotal) / 100;
      }
      return amountToSubtract;
    },
    displayDiscount() {
      return `-${this.discountFinal.toFixed(2)}`;
    },
  },
  methods: {
    setLogoFile(file) {
      console.log(file);
    },
    addLineItem() {
      this.lineItems.push({
        name: "",
        desc: "",
        rate: "",
        qty: "",
        total: "",
      });
    },
    removeLineItem(index) {
      this.lineItems.splice(index, 1);
    },
    displayLineTotal(amount) {
      const amnt = Number(amount);
      return `${this.currency}${amnt.toFixed(2)}`;
    },
    updateLineTotal(index) {
      this.lineItems[index].total =
        +this.lineItems[index].rate * +this.lineItems[index].qty;
    },
    setDiscount(object) {
      const { value, type } = object;
      this.discount.value = value;
      this.discount.type = type;
    },
    removeDiscount() {
      this.discount.value = 0;
    },
    removePaymentSchedule() {
      this.paymentSchedule.payments = [];
    },
    setDateOfIssue(event) {
      this.dateOfIssue = moment(event);
    },
    setDateOfDue(event) {
      this.dateOfDue = moment(event);
    },
    setPaymentSchedule(payments) {
      this.paymentSchedule.payments = payments;
    },
    paymentDueDateFormat(dte) {
      return moment(dte).format("MMM DD, YYYY");
    },
    paymentAmountFormat(amnt) {
      amnt = +amnt;
      return `${this.currency}${amnt.toFixed(2)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-1 {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .child-1 {
    min-width: 35%;
    // flex-grow: 2;
  }
}
.company-name {
  text-align: right;
}
.row-2 {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.hr__main {
  display: block;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-color: aquamarine;
}
.row-3,
.row-4 {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 45% 20% 15% 20%;
  .child-1 {
    justify-self: start;
  }
  .child-2 {
    justify-self: end;
  }
  .child-3 {
    justify-self: end;
  }
  .child-4 {
    justify-self: end;
    font-size: 13px;
  }
}
.row-4 {
  border-bottom: 1px solid lightgray;
  .remove-line-item__wrap {
    position: relative;
    height: 0;
    i {
      position: relative;
      cursor: pointer;
      left: 963px;
      bottom: 51px;
    }
  }
}
.row-5,
.row-6 {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 45% 30% 25%;
  .child-2,
  .child-3 {
    margin-bottom: 1rem;
    width: 100%;
    font-size: 13px;
    color: black;
    justify-self: end;
    text-align: end;
    border-bottom: 1px solid lightgray;
  }
}
.row-5 {
  .child-2 {
    .link {
      font-size: 12px;
    }
  }
  .child-3 {
    font-size: 13px;
    .remove-discount__wrap {
      height: 0;
      position: relative;
      i {
        position: relative;
        cursor: pointer;
        left: 17px;
        bottom: 19px;
      }
    }
  }
}
.add_item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed grey;
  border-radius: 4px;
  max-width: 90%;
  margin: 2rem auto;
  cursor: pointer;
}
.add_item:hover {
  background-color: #ddffe1;
}
.link {
  color: #0075dd;
  cursor: pointer;
}
.link:hover {
  opacity: 0.6;
}
.row-7 {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 45% 30% 25%;
  .child-2 {
    .label {
      color: #2dce89;
      font-size: 14px;
    }
    .link {
      font-size: 12px;
    }
    .payment-schedule__dates {
      color: black;
      font-size: 13px;
    }
  }
  .child-3 {
    color: black;
    font-size: 13px;
    .label {
      color: #2dce89;
      font-size: 14px;
    }
  }
  .child-2,
  .child-3 {
    margin-bottom: 1rem;
    width: 100%;
    justify-self: end;
    text-align: end;
    border-bottom: 1px solid lightgray;
    .remove-payment-schedule__wrap {
      height: 0;
      position: relative;
      i {
        position: relative;
        cursor: pointer;
        left: 17px;
      }
    }
  }
}
.row-8 {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 45% 30% 25%;
  .child-1 {
    justify-self: start;
  }
  .child-2 {
    justify-self: end;
    .label {
      color: #2dce89;
      font-size: 14px;
    }
  }
  .child-3 {
    justify-self: end;
    color: black;
    font-size: 13px;
  }
}
.row-notes {
  max-width: 90%;
  margin: 0 auto;
}
</style>
