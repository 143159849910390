<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    ></base-header>
    <div class="container-fluid mt--9 mb-7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Ites & Services</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    type="success"
                    @click="$router.push({ name: 'createItem' })"
                    >New Item</base-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <view-item-card
                class="mb-3"
                :product="product"
                v-for="product in products"
                :key="product.id"
              ></view-item-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewItemCard from "@/views/ItemsAndServices/view/ViewItemCard.vue";
export default {
  name: "ViewItems",
  components: { ViewItemCard },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "Heineken 33cl",
          shortDesc: "Alcoholic beverage",
          longDesc:
            "An alcoholic beverage made from malted cereal grain and brewed by slow fermentation.",
          carousel: [
            "https://images.unsplash.com/photo-1508253730651-e5ace80a7025?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
            "https://images.unsplash.com/photo-1516535794938-6063878f08cc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
          ],
          stock: `$300.78`,
          salePrice: `$45.20`,
        },
        {
          id: 2,
          title: "Computer Repair",
          shortDesc: "IT Hardware",
          longDesc:
            "Computer repair technicians; evaluates, installs, detects, and troubleshoots different issues faced by computers or servers or networks.",
          carousel: [
            "https://images.pexels.com/photos/1714208/pexels-photo-1714208.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
            "https://images.pexels.com/photos/326501/pexels-photo-326501.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          ],
          stock: `$50.78`,
          salePrice: `$415.20`,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.grid-header {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  border-top: 1px solid #ddd;
  background-color: #eaeff0;
  border-bottom: 1px solid #ddd;
  div {
    justify-self: start;
    padding: 0.5rem;
  }
}
.grid-cells {
  &:hover {
    background-color: #f5f5f5;
  }
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  div {
    font-size: 0.8rem;
    justify-self: start;
    padding: 0.5rem;
  }
}
</style>
